.dashboard {
  padding: 15px 20px;
}

.bg_layer {
  background-color: var(--color-white-smoke);
  min-height: 100vh;
}

.box_section {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard_sidebar {
  width: 20%;
}

.dashboard_panel {
  width: 80%;
}

.section_row {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  padding: 15px;
  margin-bottom: 20px;
}

.section_row_chart {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  padding: 10px;
  height: 350px;
  margin-bottom: 20px;
}

.section_title {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-text);
}

.referral_row_all {
  display: flex;
  flex: 1;
  gap: 20px;
}

.referral_row {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  padding: 20px;
  margin-bottom: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  color: var(--color-text);
}

.referral_row > img {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
}

.referral_row > span {
  font-size: 16px;
  font-weight: 600;
}

.referral_row > p {
  font-size: 12px;
  font-weight: 400;

  text-align: center;
}

.referral_row:hover {
  background-color: #f7b100;
  color: white;
  transition: all 0.5s ease-in-out;
}

.top_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}

.bottom_row {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.full_row {
  display: flex;
  flex: 1;
}

.edit_section {
  flex: 0.62;
}

.status_section {
  flex: 0.38;
  padding-left: 10px;
}

.status_container {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 2px 0px #0000006e;
  border-radius: 10px;
  margin-bottom: 20px;
}

.status_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: var(--scolor-text);
  margin-bottom: 10px;
  cursor: pointer;
}

.status_bar_active {
  background-color: #27ae60;
}

.status_bar_pending {
  background-color: #ffc940;
  color: var(--color-text);
}

.status_bar_inactive {
  background-color: #bd0000;
}

.status_bar_default {
  background-color: #525252;
}

.edit_container {
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 15px;
  /* box-shadow: 0 0 2px 0px #0000006e; */
  box-shadow: var(--box-shadow-base);
  border-radius: 10px;
  margin-bottom: 20px;
}

.request_row {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-bottom: 15px;
}

.remaining_time {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 10px;
}

.remaining_time > span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
}

.setting_box {
  margin: 0;
  padding: 0;
  list-style: none;
}

.setting_box > li {
  background-color: white;
  border-radius: 10px;
  padding: 10px 10px;
  margin-bottom: 2px;
}

.setting_box_content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.setting_box > li:hover {
  background-color: whitesmoke;
}

.setting_box_title > h3 {
  font-size: var(--font-size-base);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

.setting_box_title > p {
  font-size: var(--font-size-xs);
  color: var(--color-text);
  margin: 0;
  padding: 0;
  margin-top: -3px;
}

.setting_box_content_main > .anticon {
  font-size: 16px;
  color: var(--color-text);
}

.setting_box_content > svg {
  font-size: 25px;
  color: var(--color-text);
}

.switch_line {
  display: flex;
  align-items: center;
}

.switch_line > p {
  margin-left: 10px;
}

.common_header {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: 600;
  line-height: 1.3;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.common_header > span {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-sm);
  color: var(--color-gray);
  font-weight: 400;
  margin-left: 5px;
}

.row_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.input_field {
  width: 50%;
}

.left_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.cus_data > div {
  margin-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.cus_data > div > p {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  width: 120px;
}

.cus_data > div > span {
  font-weight: 600;
  width: 250px;
}

.cus_image > img {
  object-fit: cover;
  height: 150px;
  width: 150px;
}

.check_list {
  margin-bottom: 7px;
}

.step_board {
  margin-top: 5px;
}

.uni_data_dox {
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.doc_list {
  display: inline-block;
  list-style: none;
}

.doc_list > li {
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.doc_list > li > p {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: 500;
}

.edu_section {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 20px;
}

.app_view_section {
  display: flex;
  justify-content: space-between;
}

.app_view_nav {
  width: 24%;
}
.app_view_body {
  width: 74%;
}
.edu_nav_btn {
  padding: 10px;
  background-color: var(--color-white-smoke);
  border-radius: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  color: var(--color-text);
}

.edu_nav_btn:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: var(--transition);
}

.edu_nav_btn_active {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: var(--transition);
}

.edu_pp {
  transition: var(--transition);
}

.ant-input::placeholder {
  color: rgb(165, 165, 165);
}

.ant-picker-input input::placeholder {
  color: rgb(165, 165, 165);
}

.ant-select-selection-placeholder {
  color: rgb(165, 165, 165);
}

.collapseList {
  margin-bottom: 20px;
}

.app_upload {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.empty_box_li {
  margin: 0 !important;
}

.empty_box {
  background-color: whitesmoke;
  border: 1px dashed gray;
  border-radius: 10px;
  text-align: center;
  width: 165px;
  display: table;
  padding: 60px 10px;
  cursor: pointer;
}

.is_ref_doc {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.assign_box {
  text-align: center;
  background-color: #faad147a;
  border: 1px solid #faad14;
  margin-bottom: 3px;
  color: #644300;
  font-size: 10px;
  cursor: pointer;
}

.assign_box_not {
  text-align: center;
  background-color: #d9363e52;
  border: 1px solid #d9363e;
  margin-bottom: 3px;
  color: #d9363e;
  font-size: 10px;
  cursor: pointer;
}

.assign_box_deleted {
  text-align: center;
  background-color: #ff000d;
  border: 1px solid #ff000d;
  margin-bottom: 3px;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
}

.assign_box > .app_name {
  font-weight: 500;
}

.assign_box_not > .app_name {
  font-weight: 500;
}

.assign_box_deleted > .app_name {
  font-weight: 500;
  text-decoration: line-through;
}

.view_box_body {
  margin-top: 15px;
}

.view_row {
  display: grid;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  grid-template-columns: 30% 70%;
  gap: 20px;
}

.view_row > .type {
  font-size: var(--font-size-base);
  color: var(--color-text);
  font-weight: 500;
}

.view_row > .data {
  font-size: var(--font-size-base);
  color: var(--color-text);
}

.view_doc_btn {
  background-color: #234caa4f;
  padding: 5px 10px;
  font-size: var(--font-size-base);
  border: 1px solid #234caa;
  border-radius: 5px;
  font-weight: 500;
}

.view_doc_btn > i {
  margin-left: 10px;
}

.view_doc_btn:hover {
  background-color: #234caa;
  color: #fff;
  transition: var(--transition);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.color_tab {
  width: 50px;
  height: 16px;
  border: 1px solid #adadad;
  border-radius: 5px;
}

.color_row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.color_row > p {
  margin: 0;
  font-size: var(--font-size-base);
  color: var(--color-text);
}

.refreshBtn {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: #646464;
  /* background-color: #787878; */
  padding: 4px;
  border-radius: 5px;
}

.refreshBtn:hover {
  color: var(--color-text);
  transition: var(--transition);
}

.alertTxt > li {
  font-size: 12px;
  line-height: 1.5;
}

.alLink {
  color: #2f69f0;
  font-weight: 600;
  cursor: pointer;
}

.spaces {
  margin-bottom: 10px;
}

.spam_box {
  background-color: #e7e7e7;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
  margin: 1px;
}

.setting_box_content_main {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.filter_row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  gap: 10px;
}

.form__description {
  font-size: 12px;
  color: #333;
}

.characters {
  margin: 0;
  padding: 0;
}

.do_file {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 8px;
  justify-content: space-between;
  margin-bottom: 10px;
  color: var(--color-text);
  border: 1px solid #bcbcbc;
}

.do_file_btn {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: var(--box-shadow-base);
}

.do_file_btn:hover {
  background-color: #f7b100;
  color: white;
  transition: var(--transition);
}

.data_row {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #d7d7d7;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
}

.data_row > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.data_row > div > a {
  color: var(--color-text);
}

.data_row > div > a:hover {
  text-decoration: underline;
}

.select_op {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.course_details {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 10px;
}

.cd_row > span {
  font-size: var(--font-size-base);
  color: var(--color-text);
  font-weight: 500;
}

.cd_row > p {
  font-size: 12px;
  margin-bottom: 5px;
}

.srap_btn_row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.ag_center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.ag_center_box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 90%;
  text-align: center;
}

.ag_center_box h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
}

.ag_center_box p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

.signature-box {
  width: 100%;
  margin-bottom: 20px;
}

.signature-box h3 {
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
}

canvas {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fefefe;
  cursor: crosshair;
}



.uploaded-signature {
  margin-top: 10px;
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: contain;
}

.btn_row {
  display: flex;
  justify-content: center;
  gap: 10px;
}